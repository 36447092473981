import React, { useState, useReducer } from 'react';
import Header from '@components/Header';
import Footer from '@components/Footer';
import Page from '@components/Page';
import { css } from '@emotion/react';
import { getPageElements } from '@lib/utils';
import { graphql } from 'gatsby';
import PlanCard from '@components/theme/PlanCard';
import { parseQueryTags, assignQueryValue, getTagsFromSessionStorage } from '@lib/searchQuery';

const PLANS = [
  {
    id: '1',
    title: 'Beverage cost-efficiency manager',
    short: 'Chcę obniżyć koszty związane ze sprzedażą napojów',
    long: 'a) kontrola zużycia surowców b) czy zużywasz tyle surowców ile planujesz? porównanie rzeczywistego beverage costu % z planowanym beverage costem % c) Twoje wydatki na zakupy są zbyt wysokie w porównaniu do sprzedaży d) skontroluj swoich pracowników',
    features: [6, 1, 4, 3, 8, 13, 15, 14]
  },
  {
    id: '2',
    title: 'Food cost-efficiency manager',
    short: 'Chcę obniżyć koszty związane ze sprzedażą dań',
    long: 'a) kontrola zużycia surowców b) czy zużywasz tyle surowców ile planujesz? porównanie rzeczywistego beverage costu % z planowanym beverage costem % c) Twoje wydatki na zakupy są zbyt wysokie w porównaniu do sprzedaży d) skontroluj swoich pracowników',
    features: [6, 1, 4, 3, 8, 13, 15, 14]
  },
  {
    id: '3',
    title: 'Hotel\'s cost-efficiency manager',
    short: 'Chcę kontrolować i ograniczyć koszty surowców w hotelu',
    long: 'a) kontrola zużycia surowców b) czy zużywasz tyle surowców ile planujesz? porównanie rzeczywistego beverage costu % z planowanym beverage costem % c) Twoje wydatki na zakupy są zbyt wysokie w porównaniu do sprzedaży d) skontroluj swoich pracowników',
    features: [6, 1, 4, 3, 8, 13, 15, 14]
  },
  {
    id: '4',
    title: 'Menu engineering manager',
    short: 'Chcę zarabiać więcej z menu engineeringiem',
    long: 'a) kontrola zużycia surowców b) czy zużywasz tyle surowców ile planujesz? porównanie rzeczywistego beverage costu % z planowanym beverage costem % c) Twoje wydatki na zakupy są zbyt wysokie w porównaniu do sprzedaży d) skontroluj swoich pracowników',
    features: [6, 1, 4, 3, 8, 13, 15, 14]
  },
  {
    id: '5',
    title: 'Autonomous Management',
    short: 'Chcę uniezależnić się od kompetencji moich pracowników ',
    long: 'a) kontrola zużycia surowców b) czy zużywasz tyle surowców ile planujesz? porównanie rzeczywistego beverage costu % z planowanym beverage costem % c) Twoje wydatki na zakupy są zbyt wysokie w porównaniu do sprzedaży d) skontroluj swoich pracowników',
    features: [6, 1, 4, 3, 8, 13, 15, 14]
  },
  {
    id: '6',
    title: 'Multi-venues cost-efficiency manager',
    short: 'Chcę mieć łatwą kontrolę nad wieloma punktami sprzedażowymi',
    long: 'a) kontrola zużycia surowców b) czy zużywasz tyle surowców ile planujesz? porównanie rzeczywistego beverage costu % z planowanym beverage costem % c) Twoje wydatki na zakupy są zbyt wysokie w porównaniu do sprzedaży d) skontroluj swoich pracowników',
    features: [6, 1, 4, 3, 8, 13, 15, 14]
  },
  {
    id: '7',
    title: 'Corporate Social Responsibility (CSR) Manager',
    short: 'Chcę mierzyć i redukować negatywny wpływ biznesu na środowisko',
    long: 'a) kontrola zużycia surowców b) czy zużywasz tyle surowców ile planujesz? porównanie rzeczywistego beverage costu % z planowanym beverage costem % c) Twoje wydatki na zakupy są zbyt wysokie w porównaniu do sprzedaży d) skontroluj swoich pracowników',
    features: [6, 1, 4, 3, 8, 13, 15, 14]
  },
  {
    id: '8',
    title: 'Certified 3rd party auditor',
    short: 'Chcę mieć niezależnego audytora w swoim lokalu',
    long: 'a) kontrola zużycia surowców b) czy zużywasz tyle surowców ile planujesz? porównanie rzeczywistego beverage costu % z planowanym beverage costem % c) Twoje wydatki na zakupy są zbyt wysokie w porównaniu do sprzedaży d) skontroluj swoich pracowników',
    features: [6, 1, 4, 3, 8, 13, 15, 14]
  }
];

const HorisontalFormInput = ({ label, value, onChange }) => (
  <div className="row mb-1">
    <div className="col-md-6">
      <div css={css`
                    display: flex;
                    flex-grow: 1;
                    width: 100%;
                    height: 100%;
                    justify-content: flex-start;
                    align-items: center;
                  `}
      >
        <label className="mb-0 h5">{label}</label>
      </div>
    </div>
    <div className="col-md-6" >
      <input type="text" className="form-control" value={value} onChange={onChange} />
    </div>
  </div>
)

export default function Flow({
  data: { page },
  pageContext: { language, refs, layouts, configuration },
  location
}) {
  const { body } = getPageElements(page);
  const query = parseQueryTags(location, ['plans']);
  const plans = query.plans ? query.plans.split(',') : [];
  const [selectedPlans, setSelectedPlans] = useState([]);
  const [calculator, dispatch] = useReducer((state, { key, value }) => ({ ...state, [key]: value }), {
    bev: 150000,
    food: 250000,
    rooms: 200,
    events: 250,
    venues: 1
  })

  const toggleSelect = (id) => {
    if (selectedPlans.includes(id)) {
      setSelectedPlans(selectedPlans.filter((planId) => planId !== id));
    } else {
      setSelectedPlans([...selectedPlans, id]);
    }
  };

  return (
    <Page>
      <Header
        dark
        language={language}
        refs={refs}
        layouts={layouts}
      />
      <div
        className="py-5"
        css={css`
          h2,
          h3,
          h4 {
            margin-top: 1em;
          }
        `}
      >
        <div className="container">
          <h1 className="mb-4 text-center">{'My business case'}</h1>
          <div className="card card-sm card-body shadow-sm">
            <h3 className="card-title text-center mt-0 mb-3">
              {'Your Business'}
            </h3>
            <HorisontalFormInput label="Napoje sprzedajesz za" value={calculator.bev} onChange={
              event => dispatch({ key: 'bev', value: event.target.value })
            } />
            <HorisontalFormInput label="Jedzenie sprzedajesz za" value={calculator.food} onChange={
              event => dispatch({ key: 'bev', value: event.target.value })
            } />
            <HorisontalFormInput label="Twoja liczba pokojów" value={calculator.rooms} onChange={
              event => dispatch({ key: 'bev', value: event.target.value })
            } />
            <HorisontalFormInput label="Twoja powierzchnia eventowa (m2)" value={calculator.events} onChange={
              event => dispatch({ key: 'bev', value: event.target.value })
            } />
            <HorisontalFormInput label="Liczba lokali" value={calculator.venues} onChange={
              event => dispatch({ key: 'bev', value: event.target.value })
            } />
          </div>
          <div className="card card-sm card-body shadow-sm">
            <h3 className="card-title text-center mt-0 mb-3">
              {'Wybrane Pakiety'}
            </h3>
            <ul className="list-group mb-3">
              {PLANS.filter(({id}) => plans.includes(id)).map((plan) => (
                <li className="list-group-item" key={plan.id}>
                  <strong>{plan.title}</strong>
                </li>
              ))}
            </ul>
            <div className="mb-1" style={{ fontSize: '1.2rem', textAlign: 'right' }}><span>{'Suma cen pakietów: '}</span><strong><s>{'698'}</s></strong></div>
            <div className="mb-1" style={{ fontSize: '1.2rem', textAlign: 'right' }}><span>{'Twoja cena za dwa pakiety: '}</span><strong>{'599'}</strong></div>
            <div className="mb-1" style={{ fontSize: '1.2rem', textAlign: 'right' }}><span>{'Oszczędzasz: '}</span><strong>{'99'}</strong></div>
          </div>
          <div className="card card-sm card-body shadow-sm">
            <h3 className="card-title text-center mt-0 mb-3">
              {'Przewidywane miesięczne oszczędności'}
            </h3>
            <ul className="list-group mb-3">
              {PLANS.filter(({ id }) => plans.includes(id)).map((plan) => (
                <li className="list-group-item" key={plan.id}>
                  <strong>{plan.title}</strong>
                </li>
              ))}

            </ul>
            <div className="mb-1" style={{ fontSize: '1.2rem', textAlign: 'right' }}><span>{'Miesięczne oszczędności with No Spoilers: '}</span><strong>{'8280'}</strong></div>
            <div className="mb-1" style={{ fontSize: '1.2rem', textAlign: 'right' }}><span>{'To oznacza że rocznie oszczędzisz: '}</span><strong>{'99360'}</strong></div>
            <div className="mb-1" style={{ fontSize: '1.2rem', textAlign: 'right' }}><span>{'ZYSK po roku używania No Spoilers: '}</span><strong className='h3'>{'92721'}</strong></div>
          </div>
          <div className="mb-3" css={css` display: flex; width: 100%; justify-content: center; `}> <a href={`/buy?plans=${plans.join(',')}`} className={'btn btn-primary btn-lg shadow-sm'}>Weź zysk - weź No Spoilers</a> </div>
          <h5 className="text-center">{'Masz dodatkowe pytania?'} <a href={'/contact'}>{'Umów się z nami.'}</a></h5>
          <p className="text-muted">{'disclaimer'}</p>
        </div>
      </div>

      <Footer
        configuration={configuration}
        language={language}
        layouts={layouts}
      />
    </Page>
  );
}

export const query = graphql`
  query($id: String) {
    page: wpWebsite(id: { eq: $id }) {
      ...Page
    }
  }
`;